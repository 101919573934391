import i18n from '@/i18n'
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { ReportHeading } from '../../components/ReportHeadingPdf'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, monthlyReport, vm, search) => {
  try {
        Store.commit('mutateCommonProperties', {
          loading: true
        })
        if (i18n.locale === 'bn') {
          pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
          const pdfContent = []
          const allRowsHeadTwo = []
          const rowItemTwo = ReportHeading(vm)
          allRowsHeadTwo.push(...rowItemTwo)
          allRowsHeadTwo.push(['', '', ''])
          allRowsHeadTwo.push(['', '', ''])
          allRowsHeadTwo.push(['', '', ''])
          allRowsHeadTwo.push(['', '', ''])
          allRowsHeadTwo.push(['', '', ''])
          pdfContent.push({
            table: {
              widths: ['20%', '60%', '20%'],
              body: allRowsHeadTwo
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          })

        // }

        const allRowsHead1 = [
          [
            { text: vm.$t('globalTrans.fiscal_year') + ' : ' + vm.getFiscalYear(search.fiscal_year_id), alignment: 'left', style: 'thh' },
            { text: vm.$t('tcb_report.month') + ' : ' + vm.getMonthName(search.month), alignment: 'left', style: 'thh' },
            { text: '' + '' + '', alignment: 'left', style: 'thh' }
          ]
        ]

        allRowsHead1.push([
          { text: '' + '' + '', alignment: 'left', style: 'thh' },
          { text: '' + '' + '', alignment: 'left', style: 'thh' },
          { text: '' + '' + '', alignment: 'left', style: 'marginForUse' }
        ])

        pdfContent.push({
          table: {
            headerRows: 1,
            widths: '*',
            body: allRowsHead1
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })

       const ProductWiseRows = [
          [{ text: vm.$t('tcb_report.monthly_stock_report_all_items'), colSpan: 7, style: 'tablecell' }, 'Column 2', 'Column 3', 'Column 1', 'Column 2', 'Column 3', 'Column 4'],
          [
            { text: vm.$t('eBizConfig.product_name'), style: 'tablecell' },
            { text: vm.$t('tcb_report.monthly_opening_stock'), style: 'tablecell' },
            { text: vm.$t('tcb_report.receive_new_purchases'), style: 'tablecell' },
            { text: vm.$t('globalTrans.total') + (vm.$n(2) + '+' + vm.$n(3)), style: 'tablecell' },
            { text: vm.$t('tcb_report.monthly_demand'), style: 'tablecell' },
            { text: vm.$t('tcb_report.monthly_distribution'), style: 'tablecell' },
            { text: vm.$t('tcb_report.monthly_end_stock'), style: 'tablecell' }
          ]
        ]

        Object.entries(monthlyReport).forEach(([catId, category]) => {
          ProductWiseRows.push([
            { text: vm.getItemCategoryName(catId), style: 'tablecell' },
            { text: vm.$n(category.initial_stock), style: 'tablecell' },
            { text: vm.$n(category.total_stock_in), style: 'tablecell' },
            { text: vm.$n(parseFloat(category.initial_stock + category.total_stock_in)), style: 'tablecell' },
            { text: vm.$n(category.total_demand), style: 'tablecell' },
            { text: vm.$n(category.total_distribution), style: 'tablecell' },
            { text: vm.$n(parseFloat(category.initial_stock + category.total_stock_in) - parseFloat(category.total_distribution)), style: 'tablecell' }
          ])
        })
      pdfContent.push({
        style: 'tableExample',
        table: {
          widths: ['15%', '14%', '14%', '14%', '14%', '14%', '14%'],
          body: ProductWiseRows
        }
      })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Landcape',
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 8 : 7,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 8 : 7,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          img_logo: {
            margin: [20, 0, 0, 0]
          },
          img_logo_two: {
            margin: [25, 0, 0, 0]
          },
          reportHeading: {
            fontSize: 13,
            margin: [0, 10, 0, 0]
          },
          headerTwo: {
            fontSize: 12,
            margin: [0, -10, 0, 0]
          },
          headerThree: {
            fontSize: 9,
            margin: [0, 1, 0, 0]
          },
          giveUnderline: {
            decoration: 'underline',
            margin: [0, 10, 0, 0]
          },
          tableHeader: {
            alignment: 'center'
          },
          tableHeaderTwo: {
            fontSize: 9,
            alignment: 'center',
            margin: [0, 10, 0, 0]
          },
          tableHeaderThree: {
            alignment: 'left',
            margin: [0, 0, 0, 0]
          },
          tableHeaderFour: {
            alignment: 'left',
            margin: [0, 50, 0, 0]
          },
          tableHeaderFive: {
            alignment: 'left',
            bold: true
          },
          tableExampleGrid: {
            margin: [0, 5, 5, 0]
          },
          tableHeaderSix: {
            fontSize: 15,
            margin: [0, 8, 8, 0]
          },
          tableHeaderSeven: {
            fontSize: 10,
            margin: [0, 0, 0, 0]
          },
          tableHeaderEight: {
            fontSize: 10,
            margin: [20, 0, 0, 0]
          },
          leftalign: {
            alignment: 'left'
          },
          tablecell: {
            alignment: 'center'
          },
          marginForUse: {
            margin: [0, 20, 0, 0]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {
      }
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
