<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{$t('tcb_report.monthly_stock_report_all_items')}}</h4>
      </template>
      <template v-slot:searchBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
        <b-row class="mt-4">
          <b-col lg="4" sm="4">
            <ValidationProvider name="Fiscal Year id" vid="fiscal_year_id" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-for="fiscal_year_id"
                slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                      {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                  </template>
                <b-form-select
                  plain
                  v-model="search.fiscal_year_id"
                  :options="fiscalYearList"
                  @change="fiscalYearCahnge($event)"
                  id="fiscal_year_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="4" sm="4">
            <ValidationProvider name="Month" vid="month" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-for="month"
                slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                      {{$t('tcb_report.month')}} <span class="text-danger">*</span>
                  </template>
                <b-form-select
                  plain
                  v-model="search.month"
                  :options="MonthList"
                  id="month"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{$t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
        </b-form>
        </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>
    <body-card style="overflow-x: clip;">
        <b-row class="text-right mb-5 mt-5">
          <b-col>
              <b-button variant="primary" @click="pdfExport" class="mr-2">
                    {{  $t('globalTrans.print') }}
              </b-button>
          </b-col>
        </b-row>
      <!-- table section start -->
       <b-overlay :show="loader">
      <div>
        <report-heading/>
        <b-row>
        <b-col>
          {{ $t('globalTrans.fiscal_year') }}: <strong>{{getFiscalYear(search.fiscal_year_id)}}</strong>
        </b-col>
        <b-col>
          {{ $t('tcb_report.month') }}: <strong> {{getMonthName(search.month)}} </strong>
        </b-col>
      </b-row>
          <table style="width:100%;padding:10px;margin-top:10px;">
           <thead>
              <tr>
                <th colspan="7" style="text-align: center;">{{ $t('tcb_report.monthly_stock_report_all_items') }}</th>
              </tr>
              <tr>
                <th>{{$n(1)}}</th>
                <th>{{$n(2)}}</th>
                <th>{{$n(3)}}</th>
                <th>{{$n(4)}}</th>
                <th>{{$n(5)}}</th>
                <th>{{$n(6)}}</th>
                <th>{{$n(7)}}</th>
              </tr>
              <tr>
              <th>{{$t('eBizConfig.product_name')}}</th>
              <th>{{$t('tcb_report.monthly_opening_stock')}}</th>
              <th>{{$t('tcb_report.receive_new_purchases')}}</th>
              <th>{{$t('globalTrans.total')}} ({{ $n(2) + '+' + $n(3) }})</th>
              <th>{{$t('tcb_report.monthly_demand')}}</th>
              <th>{{$t('tcb_report.monthly_distribution')}}</th>
              <th>{{$t('tcb_report.monthly_end_stock')}}</th>
            </tr>
           </thead>
            <tbody>
              <template v-if="!Array.isArray(monthlyReport) && Object.keys(monthlyReport).length > 0">
                <tr v-for="(category, catId) in monthlyReport"  :key="catId">
                  <td>
                    {{ getItemCategoryName(catId) }}
                  </td>
                  <td>
                    {{ $n(category.initial_stock) }}
                  </td>
                  <td>
                    {{ $n(category.total_stock_in) }}
                  </td>
                  <td>
                    {{ $n(parseFloat(category.initial_stock + category.total_stock_in) ) }}
                  </td>
                  <td>
                    {{ $n(category.total_demand) }}
                  </td>
                  <td>
                    {{ $n(category.total_distribution) }}
                  </td>
                  <td>
                    {{ $n(parseFloat(category.initial_stock + category.total_stock_in) - parseFloat(category.total_distribution)) }}
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                <td colspan="7">
                  {{ $t('globalTrans.noDataFound') }}
                </td>
              </tr>
              </template>
            </tbody>
          </table>
      </div>
        </b-overlay>
    </body-card>
  </div>
</template>
<script>
import ModalBaseMasterList from '@/mixins/list'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { monthlyAllPurchasedProductReport } from '../../api/routes'
import ReportHeading from '../../components/ReportHeading.vue'
import Pdf from './pdf'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    ReportHeading
  },
  data () {
    return {
      search: {
      fiscal_year_id: 0,
      start_date: '',
      end_date: '',
      month: 0,
      regional_office: 0,
      item: 0,
      warehouseList: [],
      item_category: 0
      },
      monthlyReport: [],
      initial_stock: 0,
      itemList: [],
      fiscalYear: {},
      show: false,
      loader: false
    }
  },
  created () {
  },
  watch: {
    'search.item_category': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.itemList = this.$store.state.LicenseRegistrationService.commonObj.itemList.filter(item => item.status === 1 && item.item_category_id === newVal)
      }
    }
  },
  computed: {
    regionalOfficeList (orgId = null) {
      return this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1 && item.org_id === 7 && item.is_regional_office === 1)
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: this.EngBangNum(item.text_bn) }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    MonthList () {
      return this.$store.state.commonObj.monthList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    itemCategoryList () {
      return this.$store.state.LicenseRegistrationService.commonObj.itemCategoryList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    }
  },
  methods: {
    totalConslussion (data) {
      let total = 0
      data.map(function (report, index) {
        total += report.total_stock_in - (report.total_stock_out + report.total_stock_out_pending + report.total_on_transport + report.total_truck_distribution + report.total_store_distribution)
      })
      return total
    },
      async searchData () {
        this.loader = true
        const isValid = await this.$refs.form.validate()
        if (isValid) {
          let result = null
          const loadingState = { loading: false, listReload: false }
          result = await RestApi.getData(licenseRegistrationServiceBaseUrl, monthlyAllPurchasedProductReport, this.search)
          loadingState.listReload = true

          if (result.success) {
            this.show = true
            this.monthlyReport = result.data
            this.loader = false
          } else {
            this.monthlyReport = {}
            this.loader = false
            this.$refs.form.setErrors(result.errors)
          }
        }
      },
    getItemCategoryName (catId) {
          const cateObj = this.$store.state.LicenseRegistrationService.commonObj.itemCategoryList.find(item => item.value === parseInt(catId))
          if (cateObj !== undefined) {
              if (this.$i18n.locale === 'bn') {
                  return cateObj.text_bn
              } else {
                  return cateObj.text_en
              }
          }
      },
    getOfficeName (id) {
      const data = this.$store.state.CommonService.commonObj.officeList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
          return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
          return ''
      }
    },
    getMonthName (id) {
      const data = this.$store.state.commonObj.monthList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
          return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
          return ''
      }
    },
    getFiscalYear (id) {
      const data = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
          return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
          return ''
      }
    },
    fiscalYearCahnge (event) {
      this.fiscalYear = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === event)
      this.search.start_date = this.fiscalYear.start_date
      this.search.end_date = this.fiscalYear.end_date
    },
    EngBangNum (n) {
        if (this.$i18n.locale === 'bn') {
          return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
        } else {
          return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
        }
      },
    pdfExport () {
      const reportTitle = this.$t('li_step.expiration_passed_report')
      Pdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail', 2, reportTitle, this.monthlyReport, this, this.search)
    }
  }
}
</script>
<style>
@import '../../style.css';
</style>
